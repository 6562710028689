<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);$refs.simpleRules.reset();userData=blankUserData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Package
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Display Name"
            rules="required"
          >
            <b-form-group
              label="Package Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userData.package"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Package Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="Type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.type"
                :options="typeOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required|between:0,1000000"
          >
            <b-form-group
              label="Price"
              label-for="Price"
            >
              <b-form-input
                id="Price"
                v-model="userData.price"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Lock Price"
            rules="required"
          >
            <b-form-group
              label="Lock Price"
              label-for="lock_price"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.lock_price"
                :options="lockOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="quantity"
            rules="required|between:0,1000"
          >
            <b-form-group
              label="Quantity"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                v-model="userData.quantity"
                class="text-uppercase"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="lock_quantity"
            rules="required"
          >
            <b-form-group
              label="Lock Quantity"
              label-for="lock_quantity"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.lock_quantity"
                :options="lockOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>




          <validation-provider
            #default="validationContext"
            name="duration"
            rules="required|between:1,180"
          >
            <b-form-group
              label="Duration"
              label-for="Duration"
            >
              <b-form-input
                id="Duration"
                v-model="userData.duration"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="expiry_type"
            rules="required"
          >
            <b-form-group
              label="Expire Type"
              label-for="expiry_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.expiry_type"
                :options="expiry_type_option"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="Expiry Figure"
            rules="required|between:1,365"
          >
            <b-form-group
              label="Expire Figure"
              label-for="Expiry Figure"
            >
              <b-form-input
                id="expiry_figure"
                v-model="userData.expiry_figure"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="expiry_unit"
            rules="required"
          >
            <b-form-group
              label="Expire Unit"
              label-for="expiry_unit"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.expiry_unit"
                :options="unitOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="is_monthly_charge"
            rules="required"
          >
            <b-form-group
              label="Monthly Charge"
              label-for="Monthly Charge"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.is_monthly_charge"
                :options="YNOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>



          <validation-provider
            #default="validationContext"
            name="has_commission"
            rules="required"
          >
            <b-form-group
              label="Commission"
              label-for="Commission"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.has_commission"
                :options="YNOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2 mb-5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              :disabled="processing"
              @click="submit_now()"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPackageSidebarActive',
    event: 'update:is-add-new-Package-sidebar-active',
  },
  props: {
    isAddNewPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      processing:false,
      typeOptions: [
        { label: 'Package', value: 'PACKAGE' },
        { label: 'Class', value: 'CLASS' },
        { label: 'Access', value: 'ACCESS' },
      ],
      lockOptions: [
        { label: 'Lock', value: 1 },
        { label: 'Unlock', value: 0 },
      ],
      YNOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
      expiry_type_option: [
        { label: '以購買起計', value: 'DATE' },
        { label: '堂數乘日數', value: 'QUANTITY' },
      ],
      unitOptions: [
        { label: 'DAY', value: 'DAY' },
        { label: 'MONTH', value: 'MONTH' },
        { label: 'YEAR', value: 'YEAR' },
      ],
      userData: {
        duration:60,
        expiry_figure:1,
        expiry_unit:'YEAR',
        has_commission: 'N',
        is_monthly_charge: 'N'
      },
      required,
      alphaNum,
      email,
      countries,
      blankUserData: {
        display_name: '',
        username: '',
        password: '',
        role: 'admin',
        status: '',
        has_commission:'N',
        is_monthly_charge:'N'
      },
    }
  },
  methods: {

    resetForm() {

    },
    submit_now() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
        //  this.processing = true
          this.$http.post(process.env.VUE_APP_API_BASE+'/packages/add/', this.userData)
            .then(res => {
              if (res.data.result === true) {
                this.processing = false
                this.$emit('clicked', false)
                this.userData = this.blankUserData
                this.$refs.simpleRules.reset()
              } else {
                this.processing = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      display_name: '',
      username: '',
      password: '',
      role: 'admin',
      status: 'active',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
